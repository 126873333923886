/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import domToReact from 'html-react-parser/lib/dom-to-react';

export const DATA_ROLE = 'data-role';

/** @namespace Scandiweb/AmastyFreeGift/Plugin/AmastyPromo/ParsePopupButton/addReplacementRule */
export const addReplacementRule = (originalMember, instance) => ([
    {
        query: {
            name: ['a'],
            attribs: [
                {
                    [ DATA_ROLE ]: 'ampromo-popup-show',
                },
            ],
        },
        replace: (domNode) => {
            const { children } = domNode;
            const { handlePopupOpen } = instance.props;

            if (domNode?.attribs?.['data-role'] === 'ampromo-popup-show') {
                return (
                    <button
                    // eslint-disable-next-line react/forbid-dom-props
                      className="ampromo-popup-show"
                      data-role="ampromo-popup-show"
                      onClick={ handlePopupOpen }
                    >
                    { domToReact(children, instance.parserOptions) }
                    </button>
                );
            }

            return domNode;
        },
    },
    ...originalMember,
]);

export default {
    'Component/Html/Component': {
        'member-property': {
            rules: addReplacementRule,
        },
    },
};
