export const COUNTRIES = [
    {
        label: __('Australia'), value: 'au', country_code: 'au', backgroundPosition: '0 -1088px',
    },
    {
        label: __('Austria'), value: 'at', country_code: 'at', backgroundPosition: '0 -1056px',
    },
    {
        label: __('Belgium'), value: 'be', country_code: 'be', backgroundPosition: '0 -1312px',
    },
    {
        label: __('Bulgaria'), value: 'bg', country_code: 'bg', backgroundPosition: '0 -1376px',
    },
    {
        label: __('Canada'), value: 'ca', country_code: 'ca', backgroundPosition: '0 -1792px',
    },
    {
        label: __('Croatia'), value: 'hr', country_code: 'hr', backgroundPosition: '0 -3488px',
    },
    {
        label: __('Denmark'), value: 'dk', country_code: 'dk', backgroundPosition: '0 -2368px',
    },
    {
        label: __('Estonia'), value: 'ee', country_code: 'ee', backgroundPosition: '0 -2528px',
    },
    {
        label: __('Finland'), value: 'fi', country_code: 'fi', backgroundPosition: '0 -2720px',
    },
    {
        label: __('France'), value: 'fr', country_code: 'fr', backgroundPosition: '0 -2848px',
    },
    {
        label: __('Germany'), value: 'de', country_code: 'de', backgroundPosition: '0 -2304px',
    },
    {
        label: __('Greece'), value: 'gr', country_code: 'gr', backgroundPosition: '0 -3264px',
    },
    {
        label: __('Hungary'), value: 'hu', country_code: 'hu', backgroundPosition: '0 -3552px',
    },
    {
        label: __('Ireland'), value: 'ie', country_code: 'ie', backgroundPosition: '0 -3616px',
    },
    {
        label: __('Italy'), value: 'it', country_code: 'it', backgroundPosition: '0 -3840px',
    },
    {
        label: __('Latvia'), value: 'lv', country_code: 'lv', backgroundPosition: '0 -4640px',
    },
    {
        label: __('Luxembourg'), value: 'lu', country_code: 'lu', backgroundPosition: '0 -4608px',
    },
    {
        label: __('Monaco'), value: 'mc', country_code: 'mc', backgroundPosition: '0 -3584px',
    },
    {
        label: __('Netherlands'), value: 'nl', country_code: 'nl', backgroundPosition: '0 -5504px',
    },
    {
        label: __('Norway'), value: 'no', country_code: 'no', backgroundPosition: '0 -5536px',
    },
    {
        label: __('Poland'), value: 'pl', country_code: 'pl', backgroundPosition: '0 -5888px',
    },
    {
        label: __('Portugal'), value: 'pt', country_code: 'pt', backgroundPosition: '0 -5984px',
    },
    {
        label: __('Romania'), value: 'ro', country_code: 'ro', backgroundPosition: '0 -6144px',
    },
    {
        label: __('San Marino'), value: 'sm', country_code: 'sm', backgroundPosition: '0 -6560px',
    },
    {
        label: __('Slovakia'), value: 'sk', country_code: 'sk', backgroundPosition: '0 -6496px',
    },
    {
        label: __('Slovenia'), value: 'si', country_code: 'si', backgroundPosition: '0 -6464px',
    },
    {
        label: __('Spain'), value: 'es', country_code: 'es', backgroundPosition: '0 -2656px',
    },
    {
        label: __('Sweden'), value: 'se', country_code: 'se', backgroundPosition: '0 -6400px',
    },
    {
        label: __('Switzerland'), value: 'ch', country_code: 'ch', backgroundPosition: '0 -1920px',
    },
    {
        label: __('United Kingdom'), value: 'uk', country_code: 'uk', backgroundPosition: '0 -2912px',
    },
    {
        label: __('United States'), value: 'us', country_code: 'us', backgroundPosition: '0 -7328px',
    },
    {
        label: __('Rest of the world'), value: 'row', country_code: 'row', backgroundPosition: '0 0',
    },
];
