/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable fp/no-let */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class */
import DataContainer from '@scandipwa/scandipwa/src/util/Request/DataContainer';
import React, { createRef } from 'react';

import CloseIcon from 'Component/CloseIcon';

import { COUNTRIES } from '../CountrySelector/CountrySelector.config';
import { getOrderedCountries } from '../CountrySelector/CountrySelector.container';

import './MarketingPopup.style.scss';

/** @namespace Inov8/Component/MarketingPopup/Component */
export class MarketingPopupComponent extends DataContainer {
    state = {
        show: false,
        config: {},
        email: '',
        firstName: '',
        lastName: '',
        activities: {
            run: false,
            gym: false,
            hike: false,
        },
        gender: '',
        consent: false,
        country: null,
        countryCode: null,
        toggleCountrySelectState: false,
        filteredCountries: getOrderedCountries(COUNTRIES),
    };

    __construct(props) {
        super.__construct(props);
        this.keyUpHandler = this.keyUpHandler.bind(this);
        this.countrySelectorRef = createRef();
        this.countrySelectorInputRef = createRef();
    }

    handleInputChange = (event) => {
        const {
            name, value, type, checked,
        } = event.target;

        if (type === 'checkbox') {
            if (name in this.state.activities) {
                this.setState((prevState) => ({
                    activities: {
                        ...prevState.activities,
                        [name]: checked,
                    },
                }));
            } else {
                this.setState({ [name]: checked });
            }
        } else {
            this.setState({ [name]: value });
        }
    };

    componentDidMount() {
        // localStorage.removeItem('marketing_popup_shown'); // If you want to test the popup uncomment this line

        const { showPopup } = this.props;

        if (showPopup !== undefined) {
            this.setState({ show: showPopup });
            this.setState({ showPopup });
        }

        this.initializeLocalStorage();
        this.requestMarketingPopupConfig();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.countrySelectorRef.current && !this.countrySelectorRef.current.contains(event.target)) {
            this.setState({
                toggleCountrySelectState: false,
            });
        }
    };

    keyUpHandler(event) {
        const orderedCountries = getOrderedCountries(COUNTRIES);
        const inputValue = event.target.value;
        const filteredCountries = orderedCountries.filter((country) => country.label.toLowerCase().includes(inputValue.toLowerCase()));

        this.setState({
            filteredCountries,
        });
    }

    toggleCountrySelect = () => {
        this.setState((prevState) => ({
            toggleCountrySelectState: !prevState.toggleCountrySelectState,
        }));

        this.countrySelectorInputRef.current.select();
    };

    initializeLocalStorage() {
        if (!localStorage.getItem('marketing_popup_page_count')) {
            localStorage.setItem('marketing_popup_page_count', '0');
        }
    }

    clearLocalStorage() {
        localStorage.removeItem('marketing_popup_page_count');
    }

    requestMarketingPopupConfig() {
        const config = {
            engagement_mode: 'pages',
            engagement_value: 3,
        };
        this.setState({ config, show: false }, () => {
            this.handleEngagementMode(config);
        });
    }

    handleEngagementMode(config) {
        const hasBeenShown = JSON.parse(localStorage.getItem('marketing_popup_shown')) === true;

        if (!hasBeenShown) {
            // eslint-disable-next-line radix
            let pageCount = parseInt(localStorage.getItem('marketing_popup_page_count') || '0');
            pageCount++;
            localStorage.setItem('marketing_popup_page_count', pageCount.toString());

            if (pageCount >= config.engagement_value) {
                this.setState({ show: true });
                localStorage.setItem('marketing_popup_shown', true);
            }
        }
    }

    handleClose = () => {
        this.setState({ show: false });
        this.setState({ showPopup: false });
        this.clearLocalStorage();
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const {
            email, firstName, lastName, activities, gender, consent, country, countryCode,
        } = this.state;

        const query = `
        mutation {
            submitMarketingPopupForm(
                email: "${email}",
                firstName: "${firstName}",
                lastName: "${lastName}",
                activities: {run: ${activities.run}, gym: ${activities.gym}, hike: ${activities.hike}},
                gender: "${gender}",
                consent: ${consent},
                country: "${country}",
                countryCode: "${countryCode}"
            ) {
                success
                message
            }
        }`;

        try {
            const response = await fetch('/graphql', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query }),
            });

            const responseData = await response.json();

            if (responseData.data.submitMarketingPopupForm.success) {
                this.setState({ show: false });
            } else {
                this.setState({ show: false });
            }
        } catch (error) {
            this.setState({ show: false });
        }
    };

    createClickHandler(country) {
        return () => this.handleCountryChange(country);
    }

    handleCountryChange = (selectedOption) => {
        this.setState({
            country: selectedOption.label,
            countryCode: selectedOption.value,
            toggleCountrySelectState: false,
        });
    };

    render() {
        const {
            show, email, firstName, lastName, activities, gender, consent, toggleCountrySelectState, filteredCountries, country, showPopup,
        } = this.state;
        const hasBeenShown = JSON.parse(localStorage.getItem('marketing_popup_shown')) !== true;

        if (hasBeenShown) {
            return null;
        }

        return (
            <div block={ `marketing-popup ${show || showPopup ? 'show' : ''}` }>
                <button block="marketing-popup-overlay" aria-label="Close" onClick={ this.handleClose } />
                <div block="marketing-popup-content">
                    <form block="marketing-popup-container" onSubmit={ this.handleSubmit } autoComplete="off">
                        <div block="marketing-popup-header">
                            <img block="mobile" src="https://www.inov8.com/media/wysiwyg/home-page/marketing-popup/mobile/10-percent-off-m.jpg" alt="welcome INOV8" loading="lazy" />
                            <img block="desktop" src="https://www.inov8.com/media/wysiwyg/home-page/marketing-popup/desktop/10-percent-off.jpg" alt="welcome INOV8" loading="lazy" />
                            <button aria-labelledby="Close" type="button" block="close-button" onClick={ this.handleClose }><CloseIcon /></button>
                        </div>
                        <div block="marketing-popup-body">
                            <div block="marketing-popup-body-inner">
                                <div block="marketing-popup-copy">
                                    <h3>
                                        <span>Get an extra 10% off</span>
                                        { ' ' }
                                        your first purchase when you sign up to the INOV8 newsletter
                                    </h3>
                                </div>
                                <div block="name-container">
                                    <input
                                      type="text"
                                      name="firstName"
                                      placeholder="First Name"
                                      value={ firstName }
                                      onChange={ this.handleInputChange }
                                      required
                                    />
                                    <input
                                      type="text"
                                      name="lastName"
                                      placeholder="Last Name"
                                      value={ lastName }
                                      onChange={ this.handleInputChange }
                                      required
                                    />
                                </div>
                                <input
                                  type="email"
                                  name="email"
                                  placeholder="Email"
                                  value={ email }
                                  onChange={ this.handleInputChange }
                                  required
                                  autoComplete="on"
                                />

                                <div
                                  block="marketing-popup-copy_row country-question"
                                >
                                    <div block="country-selector">
                                        <div block="PropositionBar" elem="CountrySelectorInput" data-country={ country || '' }>
                                            <input
                                              id="PropBarCountrySelector"
                                              type="text"
                                              onClick={ this.toggleCountrySelect }
                                              placeholder="Select&hellip;"
                                              onKeyUp={ this.keyUpHandler }
                                              autoComplete="country"
                                              ref={ this.countrySelectorInputRef }
                                            />
                                            { toggleCountrySelectState ? (
                                            <div
                                              ref={ this.countrySelectorRef }
                                              block="PropositionBar"
                                              elem="CountrySelector"
                                              id="country-selector"
                                            >
                                                { filteredCountries.map((country) => (
                                                    <div
                                                      onClick={ this.createClickHandler(country) }
                                                      block="PropositionBar"
                                                      elem="CountrySelector-Countries"
                                                    >
                                                        { country.label }
                                                    </div>
                                                )) }
                                            </div>
                                            ) : null }
                                        </div>
                                    </div>
                                </div>

                                <div block="marketing-popup-copy_row activity-question">
                                    <p block="activity-question">Which activities are you interested in?</p>
                                    <label htmlFor="run">
                                        <input
                                          type="checkbox"
                                          id="run"
                                          name="run"
                                          htmlFor="run"
                                          checked={ activities.run }
                                          onChange={ this.handleInputChange }
                                        />
                                        Running
                                    </label>
                                    <label htmlFor="gym">
                                        <input
                                          type="checkbox"
                                          id="gym"
                                          name="gym"
                                          checked={ activities.gym }
                                          onChange={ this.handleInputChange }
                                        />
                                        Gym
                                    </label>
                                    <label htmlFor="hike">
                                        <input
                                          type="checkbox"
                                          id="hike"
                                          name="hike"
                                          checked={ activities.hike }
                                          onChange={ this.handleInputChange }
                                        />
                                        Hiking
                                    </label>
                                </div>
                                <div block="marketing-popup-copy_row">
                                    <p>Your gender:</p>
                                    <label htmlFor="male">
                                        <input
                                          type="radio"
                                          name="gender"
                                          id="male"
                                          value="male"
                                          checked={ gender === 'male' }
                                          onChange={ this.handleInputChange }
                                        />
                                        Male
                                    </label>
                                    <label htmlFor="female">
                                        <input
                                          type="radio"
                                          name="gender"
                                          id="female"
                                          value="female"
                                          checked={ gender === 'female' }
                                          onChange={ this.handleInputChange }
                                        />
                                        Female
                                    </label>
                                    <label htmlFor="gender">
                                        <input
                                          type="radio"
                                          name="gender"
                                          id="gender"
                                          value="prefer not to say"
                                          checked={ gender === 'prefer not to say' }
                                          onChange={ this.handleInputChange }
                                        />
                                        Prefer Not To Say
                                    </label>
                                </div>
                                <div block="marketing-popup-copy_row">
                                    <label htmlFor="email">
                                        <input
                                          type="checkbox"
                                          name="consent"
                                          id="email"
                                          checked={ consent }
                                          onChange={ this.handleInputChange }
                                          required
                                        />
                                        I agree to receive emails.
                                    </label>
                                </div>
                                <button type="submit"><i>Subscribe</i></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default MarketingPopupComponent;
