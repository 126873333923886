import React, { createRef, PureComponent } from 'react';
import { changeShippingTo } from 'src/util/countryChangeUtility';

import { CountrySelectorComponent } from './CountrySelector.component';
import { COUNTRIES } from './CountrySelector.config';
import { Country, CountrySelectorProps, CountrySelectorState } from './CountrySelector.types';

import './CountrySelector.style';

// Countries to appear at the top of the dropdown
export const PREFERRED_COUNTRIES = [
    'United Kingdom', 'United States', 'Australia', 'Austria', 'Canada', 'France', 'Germany', 'Netherlands', 'Norway', 'Sweden', 'Switzerland', 'Belgium', 'Bulgaria', 'Croatia', 'Denmark', 'Estonia', 'Finland', 'Greece', 'Hungary', 'Ireland', 'Italy', 'Latvia', 'Luxembourg', 'Monaco', 'Poland', 'Portugal', 'Romania', 'San Marino', 'Slovakia', 'Slovenia', 'Spain',
];

/** @namespace Inov8/Component/CountrySelector/Container/getOrderedCountries */
export const getOrderedCountries = (countries: Country[]): Country[] => {
    const preferred = PREFERRED_COUNTRIES.map((pCountry) => countries.find((c) => c.label === pCountry)).filter(Boolean) as Country[];
    const rest = countries.filter((c) => !PREFERRED_COUNTRIES.includes(c.label));

    rest.sort((a, b) => a.label.localeCompare(b.label));

    return [...preferred, ...rest];
};

/** @namespace Inov8/Component/CountrySelector/Container */
export class CountrySelectorContainer extends PureComponent<CountrySelectorProps, CountrySelectorState> {
    private countrySelectorRef!: React.RefObject<HTMLDivElement>;

    __construct() {
        this.state = {
            toggleCountrySelector: false,
            filteredCountries: getOrderedCountries(COUNTRIES),
        };

        this.toggleCountrySelectorFunction = this.toggleCountrySelectorFunction.bind(this);
        this.createClickHandler = this.createClickHandler.bind(this);
        this.countrySelectorRef = createRef();
    }

    componentDidMount() {
        // localStorage.removeItem('country_popup_dismissed'); // Clear the flag to always show the popup for testing
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event: MouseEvent) => {
        if (this.countrySelectorRef.current && !this.countrySelectorRef.current.contains(event.target as Node)) {
            this.setState({
                toggleCountrySelector: false,
            });
        }
    };

    toggleCountrySelectorFunction() {
        this.setState((prevState) => ({
            toggleCountrySelector: !prevState.toggleCountrySelector,
        }));
    }

    createClickHandler(country: Country) {
        return () => this.handleCountryChange(country);
    }

    handleCountryChange = (selectedOption: Country) => {
        changeShippingTo(selectedOption);
        localStorage.selectedCountryForDropDown = selectedOption.country_code;
    };

    render() {
        const { toggleCountrySelector, filteredCountries } = this.state;

        const selectedCountryLabel = typeof localStorage !== 'undefined' && localStorage.selectedCountryForDropDown
            ? getOrderedCountries(COUNTRIES).find((country: Country) => country.value === localStorage.selectedCountryForDropDown)
            : null;

        if (!selectedCountryLabel) {
            localStorage.removeItem('country_popup_dismissed');
        }

        return (
            <CountrySelectorComponent
              toggleCountrySelector={ toggleCountrySelector }
              filteredCountries={ filteredCountries }
              toggleCountrySelectorFunction={ this.toggleCountrySelectorFunction }
              createClickHandler={ this.createClickHandler }
              selectedCountryLabel={ selectedCountryLabel ? selectedCountryLabel.label : '' }
              countrySelectorRef={ this.countrySelectorRef }
            />
        );
    }
}

export default CountrySelectorContainer;
