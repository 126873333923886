/* eslint-disable fp/no-let */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/forbid-dom-props */
import React, { Component, createRef } from 'react';
import {
    changeShippingTo, getCurrentCountryCodeFromURL,
} from 'src/util/countryChangeUtility';

import { COUNTRIES } from '../CountrySelector/CountrySelector.config';
import { getOrderedCountries } from '../CountrySelector/CountrySelector.container';

import './CountrySelectorPopup.style.scss';

/** @namespace Inov8/Component/CountrySelectorPopup/Component */
export class CountrySelectorPopupComponent extends Component {
    __construct(props) {
        super.__construct(props);
        this.keyUpHandler = this.keyUpHandler.bind(this);
        this.countrySelectorRef = createRef();
        this.countrySelectorInputRef = createRef();
    }

    state = {
        showPopup: false,
        toggleCountrySelectState: false,
        filteredCountries: getOrderedCountries(COUNTRIES),
    };

    componentDidMount() {
        // localStorage.removeItem('country_popup_dismissed'); // Clear the flag to always show the popup for testing
        this.determineVisibility();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.countrySelectorRef.current && !this.countrySelectorRef.current.contains(event.target)) {
            this.setState({
                toggleCountrySelectState: false,
            });
        }
    };

    keyUpHandler(event) {
        const orderedCountries = getOrderedCountries(COUNTRIES);
        const inputValue = event.target.value;
        const filteredCountries = orderedCountries.filter((country) => country.label.toLowerCase().includes(inputValue.toLowerCase()));

        this.setState({
            filteredCountries,
        });
    }

    toggleCountrySelect = () => {
        this.setState((prevState) => ({
            toggleCountrySelectState: !prevState.toggleCountrySelectState,
        }));

        this.countrySelectorInputRef.current.select();
    };

    createClickHandler(country) {
        return () => this.handleCountryChange(country);
    }

    determineVisibility() {
        const currentCountryCode = getCurrentCountryCodeFromURL();
        let userIPCountry = localStorage.getItem('user_selected_country'); // Check if user has selected a country before

        if (!userIPCountry) {
            userIPCountry = 'us'; // Default to 'us', replace with IP logic
        }

        if (currentCountryCode !== userIPCountry && !localStorage.getItem('country_popup_dismissed')) {
            this.setState({ showPopup: true });
        }
    }

    handleCountryChange = (selectedOption) => {
        changeShippingTo(selectedOption);
        localStorage.selectedCountryForDropDown = selectedOption.country_code;
        this.handleDismiss();
    };

    handleDismiss = () => {
        localStorage.setItem('country_popup_dismissed', 'true');
        this.setState({ showPopup: false });
    };

    render() {
        const {
            showPopup, toggleCountrySelectState, filteredCountries,
        } = this.state;

        if (!showPopup) {
            return null;
        }

        return (
            <div className="country-selector-popup">
                <div className="popup-overlay" />
                <div className="popup-content">
                    <h3>Select your country</h3>
                    <div block="country-selector">
                        <div block="PropositionBar" elem="CountrySelectorInput">
                            <input
                              id="CountrySelectorPopup"
                              type="text"
                              onClick={ this.toggleCountrySelect }
                              placeholder="Select&hellip;"
                              onKeyUp={ this.keyUpHandler }
                              autoComplete="off"
                              ref={ this.countrySelectorInputRef }
                            />
                            { toggleCountrySelectState ? (
                                <div
                                  ref={ this.countrySelectorRef }
                                  block="PropositionBar"
                                  elem="CountrySelector"
                                  id="country-selector"
                                >
                                    { filteredCountries.map((country) => (
                                        <div
                                          onClick={ this.createClickHandler(country) }
                                          block="PropositionBar"
                                          elem="CountrySelector-Countries"
                                        >
                                            { country.label }
                                        </div>
                                    )) }
                                </div>
                            ) : null }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CountrySelectorPopupComponent;
