import React, { Component } from 'react';

import { CountrySelectorComponentProps } from './CountrySelector.types';

/** @namespace Inov8/Component/CountrySelector/Component */
export class CountrySelectorComponent extends Component<CountrySelectorComponentProps> {
    render() {
        const {
            toggleCountrySelector,
            toggleCountrySelectorFunction,
            createClickHandler,
            filteredCountries,
            selectedCountryLabel,
            countrySelectorRef,
        } = this.props;

        return (
            <div block="CountrySelector">
                <p>Country:</p>
                <button
                  block={ toggleCountrySelector ? 'CountrySelector-HasMenu' : undefined }
                  onClick={ toggleCountrySelectorFunction }
                >
                    { selectedCountryLabel }
                </button>
                { toggleCountrySelector ? (
                    <div
                      ref={ countrySelectorRef }
                      block="CountrySelector"
                      elem="Countries-Container"
                    >
                        { filteredCountries.map((country) => (
                            <button
                              key={ country.value }
                              onClick={ createClickHandler(country) }
                              block="CountrySelector"
                              elem="Countries"
                            >
                                { country.label }
                            </button>
                        )) }
                    </div>
                ) : null }
            </div>
        );
    }
}

export default CountrySelectorComponent;
